<template>
  <div class="wrapper">
    <div class="card">
      <div class="title">
        <div class="caption">{{ $t('customer.support.title') }}</div>
      </div>
      <div class="content" style="color: #686E74">
        <div>
          {{ $t('customer.support.content') }}
        </div>
        <div v-html="$t('customer.support.contact')" class="mt-4"></div>
      </div>
      <div class="mt-5">
        <button :disabled="status" class="btn btn-solid btn-blue" @click="home">{{ $t('owlting_home_page') }}</button>
      </div>
      <div class="mt-4">
        <div class="link link-info">
          <div style="font-size: 14px" @click="cancelled">{{ $t('cancelled') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'customer.support',
  data () {
    return {
      status: false,
      url: 'https://www.owlting.com'
    }
  },
  methods: {
    home () {
      this.status = true
      this.$store.dispatch('removeJWT')
      this.$store.dispatch('removeMode')
      localStorage.clear()
      window.location.href = this.url
      return true
    },
    cancelled () {
      if (this.$store.getters.mode === 'setting') {
        this.$router.push({
          name: 'setting.index'
        })
      } else {
        this.$router.push({
          name: 'verify.index'
        })
      }
    }
  }
}
</script>
